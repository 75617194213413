body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
}

.login-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.login-container h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.login-form .form-group {
    margin-bottom: 15px;
}

.login-form label {
    display: block;
    color: #555;
    margin-bottom: 5px;
}

.login-form input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

.login-form button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    font-size: 16px;
}

.login-form button:hover {
    background-color: #0056b3;
}

@media (max-width: 600px) {
    .login-container {
        padding: 15px;
    }

    .login-form input {
        padding: 8px;
    }

    .login-form button {
        padding: 8px;
        font-size: 14px;
    }
}
