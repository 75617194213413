body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
}
  
h2 {
    text-align: center;
    color: #333;
    margin-top: 20px;
}
  
h3 {
    color: #555;
    margin-bottom: 10px;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border: 1px solid #ccc;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  
  .modal h2 {
    margin: 0 0 20px 0;
    text-align: center;
  }
  
  .modal p {
    margin: 0 0 20px 0;
    text-align: center;
  }
  
  .modal button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .modal button:hover {
    background-color: #0056b3;
  }
  
  
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}
  
.users-list, .matches-list {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}
  
.users-list ul, .matches-list ul {
    list-style-type: none;
    padding: 0;
}
  
.users-list li, .matches-list li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
  
.users-list li:last-child, .matches-list li:last-child {
    border-bottom: none;
}
  
.user-name {
    font-weight: bold;
    color: #333;
}
  
.points-bar-container {
    flex: 1;
    margin-left: 10px;
}
  
.points-bar {
    height: 20px;
    /*background: linear-gradient(90deg, #ff0000, #ff7f00, #ffff00, #7fff00, #00ff00, #00ff7f, #00ffff, #007fff, #0000ff, #7f00ff, #ff00ff, #ff007f);*/
    background: linear-gradient(90deg, #00ffff, #007fff, #0000ff);
    color: #fff;
    text-align: right;
    padding-right: 5px;
    line-height: 20px;
    border-radius: 4px;
}
  
.points {
    color: #888;
}
  
.match-details {
    display: flex;
    align-items: center;
    flex: 1;
}
  
.match-details img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}
  
.match-details span {
    margin-right: 10px;
}
  
.date-time {
    font-size: 0.8em;
    color: #888;
}
  
.prediction-inputs {
    display: flex;
    align-items: center;
}
  
.prediction-inputs input {
    width: 50px;
    padding: 5px;
    margin-right: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
}
  
.prediction-inputs button {
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
  
.prediction-inputs button:hover {
    background-color: #0056b3;
}
  
.prediction-status {
    font-weight: bold;
    color: #555;
}
  
.points-earned {
    color: red;
    font-weight: bold;
    margin-left: 10px;
}

/* Update User styles */
.update-user form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.update-user form div {
    margin-bottom: 10px;
}

.update-user form label {
    margin-bottom: 5px;
    color: #333;
}

.update-user form input {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.update-user form button {
    padding: 10px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.update-user form button:hover {
    background-color: #0056b3;
}

.update-user p {
    margin-top: 10px;
    color: green;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .match-details {
        flex-direction: column;
        align-items: flex-start;
    }

    .prediction-inputs {
        flex-direction: column;
        align-items: flex-start;
    }

    .prediction-inputs input {
        margin-bottom: 10px;
        width: 100%;
    }

    .prediction-inputs button {
        width: 100%;
        margin-top: 10px;
    }

    .points-bar-container {
        width: 100%;
        margin-left: 0;
    }
}
